<template>
  <div class="archause_home">
    <div class="mt0 mobile-mt0 extended wfill centered flex">
      <!-- <div
        class="acedge_hero"
        src="../assets/arcause_final.jpg"
        style="margin: 0px auto 0 auto;"
      ></div> -->
      <div
        class="acedge_hero"
        v-if="acedge.banner"
        :style="{
          background:
            $mq != 'mobile'
              ? 'url(' +
                (acedge.banner || {}).url +
                ') top / contain no-repeat, url(../assets/acedge_final.jpg) top / contain no-repeat'
              : 'url(' +
                (acedge.mobileBanner || {}).url +
                ') top / contain no-repeat, url(../assets/acedge_final.jpg) top / contain no-repeat'
        }"
      ></div>
    </div>
    <div class="h20 white wfill"></div>
    <div class="wrap flex section2" style="z-index: -1;">
      <div
        v-if="$mq != 'mobile'"
        style="padding: 40px; height: 320px;"
        class="section left flex vertical"
      >
        <h6>What is Acedge?</h6>
        <p v-html="acedge.description" class="mt20 fs14">
          <!-- ACEDGE is an e-learning offering from Ethos for learners in the field
          of Architecture, Construction, Engineering and Design. <br /><br />
          We offer courses on a wide range of topics spanning from architecture
          and design to engineering, construction and sustainability and
          environmental studies. We also offer workshops, webinars and summer
          schools. -->
        </p>
      </div>
      <!-- <div v-if="$mq != 'mobile'" class="hfill smear">
      </div>
      <div v-if="$mq != 'mobile'" class="cover"></div> -->
      <!-- <div
        class="hfill section right flex"
        style="position: relative; height: 400px;"
      >
        <h6
          class="mobile-fs20 fs40 myauto white together-text mxauto"
          style="text-align: left; line-height: 2em"
        >
          <span>Acedge</span> <br />
          An <span class="brown600">Ethos</span> of Learning <br />
        </h6>
        <div class="hands"></div>
      </div> -->
      <div
        v-if="$mq == 'mobile'"
        style="padding: 40px;"
        class="section left centered flex vertical"
      >
        <h6>What is Acedge?</h6>
        <p v-html="acedge.description" class="mt20 fs14">
          <!-- ACEDGE is an e-learning offering from Ethos for learners in the field
          of Architecture, Construction, Engineering and Design. <br /><br />
          We offer courses on a wide range of topics spanning from architecture
          and design to engineering, construction and sustainability and
          environmental studies. We also offer workshops, webinars and summer
          schools. -->
        </p>
      </div>
    </div>
    <!-- <h6 class="mt40" style="text-align: center;">Course Topics</h6>
    <div class="adjustable_list mt40 acedge_topics">
      <div
        v-for="(t, tix) in acedge.topics"
        @click="openModal('topic-' + tix)"
        :key="t.name"
        class="vertical flex mxauto"
      >
        <div
          v-if="t.image"
          :style="{
            background: 'url(' + t.image.url + ') center / contain no-repeat'
          }"
          class="mxauto pointer"
          style="width: 90px; height: 90px;"
        ></div>
        <p class="mxauto">{{ t.name }}</p>
        <div class="ui modal py20 px40" :id="'topic-' + tix">
          <h6 class="pb20" style="border-bottom: 1px solid #eee;">
            {{ t.name }}
          </h6>
          <div class="flex">
            <div
              v-if="t.image"
              :style="{
                background:
                  'url(' + t.image.url + ') center / contain no-repeat'
              }"
              class="mt12 pointer"
              style="width: 90px; height: 90px;"
            ></div>
            <div class="ml20 mt20">
              <div v-if="getCourses({ category: t.id }).length > 0">
                <p class="mt20 bold mb8">Courses</p>
                <a
                  :href="course.link"
                  target="_blank"
                  class="fs14"
                  v-for="course in getCourses({ category: t.id })"
                  :key="course.id"
                >
                  <p>{{ course.title }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div style="background: #865A18; text-align: center;">
      <h6
        class="pt40 pb12 mt40 white"
        style="text-align: center; background: #865A18;"
      >
        Knowledge Society
      </h6>
      <p class="center white" style="background: #865A18;">
        Meet our budding community of mentors and creators
      </p>
      <div class="pl20 mt20 centered box" style="background: #865A18;">
        <i class="search icon" style="color: white;"></i>
        <input
          class="noborder knowledge_search"
          v-model="search"
          style="color: white;"
          placeholder="Search By Name"
        />
      </div>
      <div class="mentors_parent">
        <div class="mx40 mentors mentor_list" :id="ID">
          <div
            v-for="(m, ix) in activeMentors"
            :key="ix"
            class="mentor_card pointer"
            @click="openModal('mentor-' + ix)"
          >
            <div
              :style="{
                background: 'url(' + m.image.url + ') center / cover no-repeat'
              }"
              class="image mxauto"
              style="width: calc(100% - 10px);"
            ></div>
            <p class="pt8 px20 fs12 aaa">
              {{ m.numberOfCourses }} Course{{
                m.numberOfCourses == 1 ? "" : "s"
              }}
            </p>
            <h6 class="px20 fs14 mobile-fs12 pb12">{{ m.name }}</h6>
            <div class="ui modal py20 px40" :id="'mentor-' + ix">
              <h6 class="pb20" style="border-bottom: 1px solid #eee;">
                {{ m.name }}
              </h6>
              <div class="flex">
                <div
                  :style="{
                    background:
                      'url(' + m.image.url + ') center / cover no-repeat'
                  }"
                  class="image mt20 ml0 mrauto br8 noshrink"
                  style="width: 140px; height: 140px;"
                ></div>
                <div class="ml20 mt20">
                  <div v-if="getCourses({ mentor: m.id }).length > 0">
                    <p class="mt20 bold mb8">Courses</p>
                    <a
                      :href="course.link"
                      target="_blank"
                      class="fs14"
                      v-for="course in getCourses({ mentor: m.id })"
                      :key="course.id"
                    >
                      <p>{{ course.title }}</p>
                    </a>
                  </div>
                  <p class="mt20 bold mb8">About</p>
                  <p>{{ m.about }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pb40 centered flex">
        <div
          class="w24 h24 br20 mx4 flex"
          :style="{
            background: activePage == i ? 'rgba(255, 255, 255, 0.3)' : ''
          }"
          style="color: white; cursor: pointer"
          v-for="(p, i) in new Array(mentorPages).fill(1)"
          :key="i"
          @click="activePage = i"
        >
          <span class="mauto">{{ i + 1 }}</span>
        </div>
      </div>
    </div> -->
    <div class="relative" style="border-top: 1px solid #eee;">
      <h6 class="mt40 ml36" style="text-align: left;">Let's Learn</h6>
      <p class="left mt8 ml36">Browse our courses on various topics</p>
      <div class="flex wrap">
        <div
          v-if="acedge && acedge.topics && acedge.topics.length > 0"
          class="me_chip_parent"
          style="border-bottom: 1px solid #eee;"
        >
          <div class="flex wrap">
            <div
              v-for="me in acedge.topics.filter(me => me.name != '')"
              :key="me.id"
              :class="{ active: activeTopicId == me.name }"
              @click="
                activeTopicId != me.name
                  ? (activeTopicId = me.name)
                  : (activeTopicId = '')
              "
              class="me_chip"
            >
              {{ truncate(me.name, 40) }}
              <!-- <span v-if="eventCounts[me.id]">({{ eventCounts[me.id] }})</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="ml20 mt20 flex wrap br6">
        <div
          :class="[$mq == 'mobile' && 'wfill mt4 mr12']"
          class="br4"
          style="border: 1px solid #ccc; padding: 0 12px;"
        >
          <i style="color: #555777" class="ml8 search icon"></i>
          <input
            placeholder="Search"
            type="text"
            class="search_input"
            style="border: none;"
            v-model="course_search"
          />
        </div>
        <!-- <div class="ml12 vertical flex">
          <select v-model="filter.topic">
            <option value="" selected>All Topics</option>
            <option
              v-for="topic in acedge.topics"
              :key="topic.id"
              :value="topic.name"
              >{{ truncate(topic.name, 40) }}</option
            >
          </select>
        </div> -->
        <div
          :class="[$mq == 'mobile' ? 'wfill mt8 mr12' : 'ml12']"
          class="vertical flex"
        >
          <select v-model="filter.mentor">
            <option value="" selected>All Mentors</option>
            <option v-for="m in acedge.mentors" :key="m.id" :value="m.name"
              >{{ m.name }}
            </option>
          </select>
        </div>
        <button
          v-if="roles"
          @click="editMode = !editMode"
          style="top: 40px; right: 20px;"
          class="mt8 sticky mlauto mr20 fs14 black"
        >
          Edit
        </button>
      </div>
      <div v-if="categoryExams && categoryExams.length > 0" class="division">
        <div class="line"></div>
        <div class="pill">Entrance Exams</div>
      </div>
      <div class="adjustable_list th mt40">
        <div
          v-for="t in categoryExams"
          :key="t.name"
          @click="$bus.redirect(t.link, false, true)"
          class="pointer course_card mx12 br8 vertical flex mxauto raised"
        >
          <div
            v-if="t.image"
            :style="{
              background: 'url(' + t.image.url + ') center / cover no-repeat'
            }"
            class="image mxauto"
            style="width: calc(100% - 10px); height: 120px;"
          >
            <div class="center pricing">
              <span class="old" v-if="t.old_price">₹{{ t.old_price }}</span>
              <span class="ml4 bold" v-if="t.price > 0">₹{{ t.price }}</span
              ><span class="ml4 bold" v-else>Free</span>
            </div>
          </div>
          <h6 class="px20 center mxauto fs16 mobile-fs14 mt32">
            {{ t.title }}
          </h6>
          <p class="mt12 center fs12 px20">{{ t.description }}</p>
          <div v-if="editMode" class="mt20 flex">
            <select
              class="ml4 mentorDropdown"
              @click.stop
              @change="updateCourse(t.id, { mentor: $event.target.value })"
            >
              <option value="">Mentor</option>
              <option
                v-for="m in acedge.mentors"
                :key="m.id"
                :value="m.name"
                :selected="t.mentor == m.name"
                >{{ m.name }}</option
              >
            </select>
            <select
              class="ml4"
              @click.stop
              @change="updateCourse(t.id, { category: $event.target.value })"
            >
              <option value="">Category</option>
              <option
                v-for="topic in acedge.topics"
                :key="topic.id"
                :value="topic.name"
                :selected="topic.name == t.category"
                >{{ topic.name }}</option
              >
            </select>
          </div>
          <div class="fs12 py12 px20 spaced flex wfill mtauto meta">
            <div>
              {{ t.engagement_hours }} hour<span v-if="t.engagement_hours != 1"
                >s</span
              >
            </div>
            <div>{{ t.credit }} credit<span v-if="t.credit != 1">s</span></div>
          </div>
        </div>
      </div>
      <div v-if="categoryExams && categoryExams.length > 0" class="division">
        <div class="line"></div>
        <div class="pill">Courses</div>
      </div>
      <div class="adjustable_list th mt40">
        <div
          v-for="t in categoryCourses"
          :key="t.name"
          @click="$bus.redirect(t.link, false, true)"
          class="pointer course_card mx12 br8 vertical flex mxauto raised"
        >
          <div
            v-if="t.image"
            :style="{
              background: 'url(' + t.image.url + ') center / cover no-repeat'
            }"
            class="image mxauto"
            style="width: calc(100% - 10px); height: 120px;"
          >
            <div class="center pricing">
              <span class="old" v-if="t.old_price">₹{{ t.old_price }}</span>
              <span class="ml4 bold" v-if="t.price > 0">₹{{ t.price }}</span
              ><span class="ml4 bold" v-else>Free</span>
            </div>
          </div>
          <h6 class="px20 center mxauto fs16 mobile-fs14 mt32">
            {{ t.title }}
          </h6>
          <p class="mt12 center fs12 px20">{{ t.description }}</p>
          <div v-if="editMode" class="mt20 flex">
            <select
              class="ml4 mentorDropdown"
              @click.stop
              @change="updateCourse(t.id, { mentor: $event.target.value })"
            >
              <option value="">Mentor</option>
              <option
                v-for="m in acedge.mentors"
                :key="m.id"
                :value="m.name"
                :selected="t.mentor == m.name"
                >{{ m.name }}</option
              >
            </select>
            <select
              class="ml4"
              @click.stop
              @change="updateCourse(t.id, { category: $event.target.value })"
            >
              <option value="">Category</option>
              <option
                v-for="topic in acedge.topics"
                :key="topic.id"
                :value="topic.name"
                :selected="topic.name == t.category"
                >{{ topic.name }}</option
              >
            </select>
          </div>
          <div class="fs12 py12 px20 spaced flex wfill mtauto meta">
            <div>
              {{ t.engagement_hours }} hour<span v-if="t.engagement_hours != 1"
                >s</span
              >
            </div>
            <div>{{ t.credit }} credit<span v-if="t.credit != 1">s</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Acedge",
  metaInfo() {
    return {
      title: this.acedge?.SEO?.metaTitle || "Ethos | Acedge",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content:
            this.acedge?.SEO?.metaDescription || "Online and live courses and lectures for students and professionals of the Architecture, Construction, Engineering & Design (ACED) fraternity"
        },
        {
          hid: "keywords",
          name: "keywords",
          content:
            this.acedge?.SEO?.metaKeywords || ""
        }
      ]
    }
  },
  data() {
    return {
      ID: this.random(),
      editMode: false,
      campaigns: [],
      acedge: {},
      activePage: 0,
      search: "",
      course_search: "",
      roles: "",
      activeTopicId: "",
      filter: {
        topic: "",
        mentor: ""
      }
    };
  },
  watch: {
    acedge: {
      handler: function() {
        /* eslint-disable no-undef */
        this.$nextTick(() => {
          // let slidesToShow = this.$mq == 'mobile' ? 1 : 4
          // $(`#${this.ID}`).slick({
          //   slidesToShow,
          //   arrows: true
          // })
        });
      },
      deep: true
    },
    filteredMentors: function() {
      this.activePage = 0;
    }
  },
  computed: {
    filteredCourses: function() {
      if (!this.acedge) return;
      let courses = this.acedge.courses;
      if (this.course_search) {
        courses = this.acedge.courses.filter(c => {
          let titleIncludes = c.title
            .toLowerCase()
            .includes(this.course_search.toLowerCase());
          let descriptionIncludes = c.description
            .toLowerCase()
            .includes(this.course_search.toLowerCase());
          return titleIncludes || descriptionIncludes;
        });
      }
      if (this.activeTopicId) {
        courses = courses.filter(c => c.category == this.activeTopicId);
      }
      if (this.filter.mentor) {
        courses = courses.filter(c => c.mentor == this.filter.mentor);
      }
      return courses;
    },
    categoryCourses: function() {
      return this.filteredCourses.filter(
        c => c.master_category != "entrance_exam"
      );
    },
    categoryExams: function() {
      return this.filteredCourses.filter(
        c => c.master_category == "entrance_exam"
      );
    },
    activeMentors: function() {
      let count = this.$mq == "desktop" ? 20 : this.$mq == "mobile" ? 12 : 16;
      return (
        this.acedge &&
        this.filteredMentors?.slice(
          count * this.activePage,
          count * (this.activePage + 1)
        )
      );
    },
    pastCampaigns: function() {
      return this.campaigns.filter(c => {
        return this.parseDate(c.End) <= this.parseDate(moment()._d);
      });
    },
    presentCampaigns: function() {
      return this.campaigns.filter(c => {
        return this.parseDate(c.End) > this.parseDate(moment()._d);
      });
    },
    filteredMentors: function() {
      if (!this.acedge) return [];
      if (this.search == "") return this.acedge.mentors;
      return this.acedge.mentors.filter(
        m => m.name.toLowerCase().includes(this.search.toLowerCase()) && m.image
      );
    },
    mentorPages: function() {
      let count = this.$mq == "desktop" ? 20 : this.$mq == "mobile" ? 12 : 16;
      return Math.ceil(this.filteredMentors?.length / count);
    }
  },
  mounted() {
    this.$req
      .get("acedge")
      .then(r => {
        this.acedge = r.data;
        let slidesToShow = this.$mq == "mobile" ? 3 : 4;
        $(`#${this.ID}`).slick({
          slidesToShow,
          arrows: true
        });
      })
      .catch(e => console.log(e));
    this.$bus
      .getLatestRoles()
      .then(r => {
        this.roles = r;
      })
      .catch(e => console.log(e));
  },
  methods: {
    scrollToCampaigns: function() {
      var elmnt = document.getElementById("campaigns");
      elmnt.scrollIntoView({ behavior: "smooth" });
    },
    truncate: function(str, length) {
      if (str.length > length) {
        return str.substring(0, length) + "...";
      }
      return str;
    },
    updateCourse: function(cid, body = {}) {
      let ix = this.acedge.courses.findIndex(c => c.id == cid);
      let course = this.acedge.courses[ix];
      course = {
        ...course,
        ...body
      };
      this.acedge.courses.splice(ix, 1, course);
      this.$req
        .put("acedge", { courses: this.acedge.courses }, this.$bus.headers)
        .then(() => {
          this.$bus.notify("Course updated");
        })
        .catch(e => this.$bus.notify("An error occured", e));
    },
    parseDate: function(d) {
      return parseInt(moment(d).format("YYYYMMDD"));
    },
    random: function() {
      return Math.random()
        .toString(36)
        .replace("0.", "");
    },
    getCourses: function(body) {
      return this.acedge.courses.filter(c => {
        for (let k of Object.keys(body)) {
          if (c[k] != body[k]) return false;
        }
        return true;
      });
    },
    openModal: function(id) {
      /* eslint-disable-next-line no-undef */
      $("#" + id).modal("show");
    }
  }
};
</script>

<style lang="css" scoped>
.knowledge_search {
  background: none;
  border: none;
  outline: none;
}
.knowledge_search::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.archause_home {
  width: 100%;
  position: relative;
  padding: 0px;
  overflow: hidden;
}

@media (max-width: 800px) {
  .archause_home {
    padding: 0px;
  }
}
.banner {
  padding: 8px;
  height: fit-content;
  white-space: nowrap;
  background: #252525;
  display: flex;
  justify-content: center;
}

.banner p {
  height: 24px;
  font-size: 16px;
  margin: auto 4px;
  font-weight: 600;
}
@media (max-width: 800px) {
  .banner p {
    font-size: 14px;
  }
  .banner {
    overflow-x: scroll;
  }
}
.banner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.banner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.acedge_hero {
  height: 100vw;
  width: 100%;
  background: url("../assets/acedge_final.jpg") top / contain no-repeat;
}
@media (min-width: 600px) {
  .acedge_hero {
    height: 37vw;
    width: 100%;
    background: url("../assets/acedge_final.jpg") top / contain no-repeat;
  }
}

/* .smear {
    background: url('../assets/archause_section_smear.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    position: relative;
    height: 500px;
    top: -190px;
    z-index: -1;
    left: 190px;
  } */

.section2 {
  height: fit-content;
}
@media (max-width: 800px) {
  .section2 {
    height: fit-content;
  }
}
.section.left {
  width: calc(40%);
  position: relative;
  padding: 20px;
}
.section.right {
  background: url("../assets/smear.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 0px;
  width: 60%;
}

.hands {
  background: url("../assets/books.svg");
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 100%;
  bottom: 0px;
  height: 30%;
}

/* .cover {
    border-bottom-left-radius: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 300px 80px;
    border-color: transparent transparent #B98744 transparent;
  } */
@media (max-width: 800px) {
  .section.left {
    width: 100%;
    position: relative;
  }
  .section.right {
    background: #b98744;
    width: 100%;
    position: relative;
  }
  .mobile-center {
    text-align: center;
  }
}
@media (min-width: 800px) {
  .together-text {
    font-size: 20px;
    margin-left: auto;
    margin: auto;
  }
}
.course_card {
  min-height: 370px;
  border: 1px solid #eee;
  overflow: hidden;
  margin-bottom: 12px;
}

.course_card .image {
  position: relative;
}

.course_card .pricing {
  border: 2px solid #333;
  position: absolute;
  padding: 8px 12px;
  background: white;
  border-radius: 2px;
  width: fit-content;
  left: 50%;
  transform: translatex(-50%);
  bottom: -20px;
}

.course_card .pricing .old {
  text-decoration: line-through;
}

.course_card .meta {
  color: #777;
}

.mentors {
  background: #865a18;
  padding: 80px;
  display: flex;
  flex-wrap: wrap;
}

.mentor_card {
  background: white;
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.mentors_parent {
  background: #865a18;
  padding: 40px 100px;
}
@media (max-width: 800px) {
  .mentors_parent {
    padding: 0px;
    background: #865a18;
  }
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 32px;
  line-height: 1;
  opacity: 0.75;
  color: #fff !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide {
  opacity: 0.6;
  background: white;
  transform: scale(0.85);
  transition: 0.4s;
}
.slick-current + .slick-slide {
  /* padding: 12px; */
  opacity: 1;
  transform: scale(0.95);
}
@media (max-width: 800px) {
  .slick-slide {
    opacity: 1;
    background: white;
    transform: scale(0.85);
    transition: 0.4s;
  }
  .slick-current + .slick-slide {
    /* padding: 12px; */
    opacity: 1;
    transform: scale(0.95);
  }
}
.slick-next {
  right: 35px !important;
}
.slick-prev {
  left: 35px !important;
}
.mentors {
  background: #865a18;
  padding: 40px 0px;
}
@media (max-width: 800px) {
  .mentors {
    padding: 40px;
  }
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 32px;
  line-height: 1;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mentor_list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.mentor_list > * {
  width: 20%;
}

.mentor_list > * > div {
  height: 400px;
}

@media (max-width: 2000px) {
  .mentor_list > * > div {
    height: 240px;
  }
}

@media (max-width: 1200px) {
  .mentor_list > * > div {
    height: 220px;
  }
}

.mentor_list > * {
  width: 20%;
}
@media (max-width: 1200px) {
  .mentor_list > * {
    width: 33%;
  }
}
@media (max-width: 600px) {
  .mentor_list > * {
    width: 50%;
  }
}

.me_chip {
  background: #f5f5f5;
  border-radius: 40px;
  color: #585757;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  padding: 6px 18px;
  margin: 12px 0px 12px 20px;
  transition: 0.2s;
  font-weight: 600;
  border: 2px solid transparent;
}
.me_chip:hover {
  background: #fdf7f0;
  color: #6b3e1e;
}
.me_chip.active,
.me_chip:active {
  background: #fdf7f0;
  border: 2px solid #6b3e1e;
  color: #6b3e1e;
}
</style>
